<template>
	<dl :class="styling">
		<div
			v-if="
				(job.addressText && job.addressText.length > 0) ||
				(job.location && (job.location.city || job.location.name))
			"
			class="flex mb-1"
		>
			<dt class="address self-center">
				<HokIcon name="icon:pin-2" :size="4" color="text" />
			</dt>
			<dd class="self-center" itemprop="jobLocation" itemscope itemtype="http://schema.org/Place">
				<address
					v-if="job.addressText || (job.location && (job.location.city || job.location.district))"
					itemprop="address"
					itemscope
					itemtype="http://schema.org/PostalAddress"
					class="not-italic"
				>
					<span v-if="job.addressText && job.addressText.length > 0">
						{{ job.addressText }}
						<meta
							v-if="job.location && job.location.street"
							itemprop="streetAddress"
							:content="job.location && job.location.street"
						/>
						<meta
							v-if="job.location && job.location.code"
							itemprop="postalCode"
							:content="job.location.code"
						/>
						<meta itemprop="addressLocality" :content="job.location && job.location.city" />
					</span>
					<template v-else-if="job.location">
						<span v-if="job.location.street" itemprop="streetAddress">{{
							job.location.street
						}}</span
						><template v-if="job.location.street">, </template>
						<span v-if="job.location.code" itemprop="postalCode">{{ job.location.code }}</span>
						<HokLink
							v-if="additionalJobData && additionalJobData.cityCanonical"
							:to="additionalJobData.cityCanonical"
							class="text-color-text hover:underline"
						>
							<span itemprop="addressLocality">
								{{ job.location.city || job.location.district }}
							</span>
						</HokLink>
						<span v-else itemprop="addressLocality">{{
							job.location.city || job.location.district
						}}</span>
					</template>
					<meta
						v-if="job.location && job.location.countryCode"
						itemprop="addressCountry"
						:content="job.location.countryCode.toUpperCase()"
					/>
					<meta v-if="addressRegion" itemprop="addressRegion" :content="addressRegion" />
				</address>
				<template v-else>{{ job.location.name }}</template>
			</dd>
			<span
				v-if="distance && typeof distance === 'number' && !mobileCompanyPreview"
				class="self-center ml-2"
			>
				({{ distance }} km entfernt)
			</span>
		</div>
		<div
			v-if="shownJobTypes && shownJobTypes.length"
			class="flex mb-1"
			:class="{ 'pr-20': !$isMobile.any }"
		>
			<dt class="employmentType self-center">
				<HokIcon name="icon:time" :size="4" color="text" />
			</dt>
			<dd class="flex flex-wrap">
				<template v-if="isPwa">
					<div
						v-for="(type, index) in shownJobTypes"
						:key="type"
						:data-cy="`employmentType-${index}`"
					>
						{{ type }}<span v-if="index !== shownJobTypes.length - 1" class="mr-1">, </span>
					</div>
				</template>
				<template v-else>
					<HokLink
						v-for="(type, index) in shownJobTypes"
						:key="type"
						:to="`/jobs/search?filters=jobtype-${type}`"
						:data-cy="`employmentType-${index}`"
						class="text-color-text hover:underline cursor-pointer"
					>
						{{ type }}<span v-if="index !== shownJobTypes.length - 1" class="mr-1">, </span>
					</HokLink>
				</template>
				<span v-if="job && job.types && job.types.length > shownJobTypes.length && !showAllTypes"
					><HokButton
						is-text
						font-weight="normal"
						color="main"
						class="ml-2"
						@click="showAllTypes = true"
					>
						alle Anzeigen</HokButton
					></span
				>
			</dd>
			<meta
				v-if="additionalJobData && additionalJobData.type"
				itemprop="employmentType"
				:content="additionalJobData.type"
			/>
		</div>
		<div v-if="job.wage && job.wage.trim().length > 0" class="flex mb-1">
			<dt class="baseSalary self-center">
				<HokIcon name="icon:euro" :size="4" color="text" />
			</dt>
			<dd
				itemprop="baseSalary"
				itemscope
				itemtype="http://schema.org/MonetaryAmount"
				class="self-center"
			>
				<span
					v-if="additionalJobData && additionalJobData.wage"
					itemprop="value"
					itemscope
					itemtype="http://schema.org/QuantitativeValue"
				>
					{{ job.wage }}
					<meta itemprop="unitText" :content="additionalJobData.wage.unit" />
					<meta itemprop="value" :content="additionalJobData.wage.value" />
				</span>
				<span v-else>
					{{ job.wage }}
				</span>
				<meta
					v-if="additionalJobData && additionalJobData.wage"
					itemprop="currency"
					content="EUR"
				/>
			</dd>
		</div>
		<div
			v-if="job.wage && jobRegion === 'at' && grossNet && !mobileCompanyPreview"
			class="flex"
			:class="{ 'mb-1': $isMobile.any }"
		>
			<HokIcon name="icon:calculator" :size="4" color="main" class="mr-1" />

			<HokButton is-text font-weight="light" color="main" clss="flex-1" @click="$emit('go-to-gnc')"
				>Netto Gehalt herausfinden</HokButton
			>
		</div>
	</dl>
</template>
<script lang="ts">
import { IAPIJobForUser, IAPILocation } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { getDistanceInKm } from '../helpers/distance';

export default defineComponent({
	name: 'JobFacts',
	emits: ['go-to-gnc'],
	data() {
		return {
			showAllTypes: false
		};
	},
	computed: {
		distance() {
			return this.userLocation && getDistanceInKm(this.job.location, this.userLocation);
		},
		addressRegion() {
			return this.job.location?.county?.toUpperCase() || this.job.internal?.region?.toUpperCase();
		},
		jobRegion() {
			return this.job?.internal?.region;
		},
		shownJobTypes(): string[] | undefined {
			if (this.showAllTypes) {
				return this.job?.types;
			}
			return this.job?.types?.slice(0, 3);
		}
	},
	props: {
		job: { type: Object as PropType<IAPIJobForUser>, required: true },
		mobileCompanyPreview: { type: Boolean, default: false },
		additionalJobData: { type: Object, required: false },
		styling: {
			type: String,
			default: 'list-small',
			validator: (value: string) => ['list-small', 'list-base'].includes(value)
		},
		userLocation: {
			type: Object as PropType<IAPILocation>,
			required: false
		},
		grossNet: { type: Boolean, default: true },
		isPwa: { type: Boolean, default: false }
	}
});
</script>
<style scoped lang="scss">
dl {
	margin-top: 0.75rem;
	font-size: $font-sm;
	&.list-base {
		font-size: $font-base;
	}

	dt {
		margin-right: 0.25rem;
	}
}
</style>
