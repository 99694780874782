<template>
	<div :class="styling">
		<template v-if="requiredFields && requiredFields.length > 0">
			<template v-for="(rqf, index) in requiredFields">
				<div v-if="rqf.type === 'EDUCATIONLEVEL'" :key="index" class="rqf">
					<HokIcon name="icon:education-new" :size="7" class="absolute top-3 left-0" />
					<div class="rqf-text">
						<span class="cate">
							{{ rqf['level-text'] }}
						</span>
						<div class="rqf-subheadline">
							{{ rqf.acceptSimilar ? 'oder vergleichbare Qualifikation' : 'gewünschte Ausbildung' }}
						</div>
					</div>
				</div>
				<div v-if="rqf.type === 'JOBLEVEL'" :key="index" class="rqf">
					<HokIcon name="icon:experience" :size="7" class="absolute top-3 left-0" />
					<div class="rqf-text">
						<p class="cate mb-0">
							{{ mainfieldExperienceText(rqf.yearsexperience, rqf['mainfield-text']) }}
						</p>
						<div v-if="rqf['subfield1-text'] || rqf['subfield2-text'] || rqf['subfield3-text']">
							Alternativ:
							<template v-if="rqf['subfield1-text']">
								{{ rqf['subfield1-text'] }}
							</template>
							<template v-if="rqf['subfield2-text']"> , {{ rqf['subfield2-text'] }} </template>
							<template v-if="rqf['subfield3-text']"> , {{ rqf['subfield3-text'] }} </template>
						</div>
						<div v-else class="rqf-subheadline">gewünschte Erfahrung</div>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'JobRequiredFields',
	methods: {
		mainfieldExperienceText(years: number, mainfield: string): string {
			switch (years) {
				case 0:
					return `Einsteiger als ${mainfield}`;
				case 1:
					return `1-3 Jahre ${mainfield}`;
				case 3:
					return `3-6 Jahre ${mainfield}`;
				case 6:
					return `6+ Jahre ${mainfield}`;
				case -1:
				default:
					return `${mainfield} von Vorteil`;
			}
		}
	},
	props: {
		requiredFields: { type: Array, default: () => [] },
		styling: {
			type: String,
			default: 'rqf-wrapper',
			validator: (value: string) => ['rqf-wrapper', 'rqf-wrapper-no-line'].includes(value)
		}
	}
});
</script>

<style lang="scss" scoped>
.rqf {
	position: relative;
	padding-left: 40px;
	padding-top: 10px;
	font-size: $font-xs;
	.cate {
		font-size: $font-sm;
		font-weight: bold;
	}
	&:nth-child(1) {
		margin-top: 10px;
		border-top: 1px solid $color-grey-lightest;
	}

	::v-deep(.rqf-icon) {
		position: absolute;
		left: 0px;
		top: 10px;
		height: 30px !important;
		width: 30px !important;
		fill: $color-blue-grey;
	}
}

.rqf-wrapper-no-line {
	.rqf {
		&:nth-child(1) {
			margin-top: -10px;
			border-top-width: 0px;
		}
		.cate {
			font-size: $font-base;
			font-weight: normal;
		}
	}
}
</style>
